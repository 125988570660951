import React from "react"
import ContentContainer from "../../templates/ContentContainer"

interface HeroProps {
    title?: string
    description?: string
    img?: string
}

const Hero: React.FC<HeroProps> = ({ title, description, img }) => {
    return (
        <section
            className="pt-[108px] h-[500px] bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url(${img})` }}
        >
            <div className="flex h-full justify-center items-center">
                <ContentContainer>
                    <div className="text-center ">
                        <h2 className="font-proxima text-white text-4xl md:text-5xl font-bold [text-shadow:0_2px_4px_#0000001f]">
                            {title}
                        </h2>
                        <h4 className="font-proxima text-white text-xl md:text-2xl font-medium mt-2">
                            {description}
                        </h4>
                    </div>
                </ContentContainer>
            </div>
        </section>
    )
}

export default Hero
