import React from "react"
import Button from "../../../components/OldDesign/Button"
import ContentContainer from "../../../templates/ContentContainer"
import { URLProvider } from "../../../utils/URLProvider"

const SupernodesButtons = () => {
    return (
        <section className="py-24 bg-old-design-gray text-center">
            <ContentContainer>
                <div className="flex flex-col items-center md:flex-row justify-center gap-5">
                    <div className="md:max-w-[400px] w-full">
                        <Button href={URLProvider.URL_SUPERNODES_SETUP} newTab>
                            SETUP YOUR SUPER NODES NOW!
                        </Button>
                    </div>
                    <div className="md:max-w-[400px] w-full">
                        <Button
                            href={URLProvider.URL_SUPERNODES_TRACKER}
                            newTab
                        >
                            SUPER NODE TRACKER
                        </Button>
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default SupernodesButtons
