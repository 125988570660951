import React from "react"
import Link from "../../../components/OldDesign/Link"
import ContentContainer from "../../../templates/ContentContainer"
import { URLProvider } from "../../../utils/URLProvider"
import DiscordImg from "../../../assets/OldDesign/media/discord.png"

const MediaDiscord = () => {
    return (
        <section className="bg-horizen-dark-blue text-center font-proxima text-xl text-white py-10 lg:py-5">
            <ContentContainer>
                <div className="flex flex-col items-center mx-auto max-w-[400px] justify-center lg:flex-row lg:max-w-full">
                    <img
                        className=" object-contain mb-4 w-12 lg:h-8 lg:w-8 lg:mr-3 lg:mb-0"
                        src={DiscordImg}
                        alt="Discord"
                    />
                    <p>
                        Join the conversation with our Secure Node operators on{" "}
                        <Link href={URLProvider.URL_DISCORD}>
                            Horizen Discord
                        </Link>
                    </p>
                </div>
            </ContentContainer>
        </section>
    )
}

export default MediaDiscord
