import React from "react"

interface SectionTitle {
    text: string
    color?: "white" | "blue"
    alignment?: "left" | "center"
}

const SectionTitle: React.FC<SectionTitle> = ({
    text,
    color = "blue",
    alignment = "center",
}) => {
    return (
        <h2
            className={`flex flex-col text-4xl font-bold uppercase after:[content:''] after:[background:#0e9de5_linear-gradient(to_right,_#0e9de5,_#26db8d)] after:h-[5px] after:w-[100px] after:my-[10px] after:mx-auto after:block 
            ${
                alignment === "left"
                    ? "text-left items-start after:!ml-0"
                    : "text-center items-center"
            }
            ${color === "blue" ? "text-old-design-dark-blue" : "text-white"}`}
        >
            {text}
        </h2>
    )
}

export default SectionTitle
