import React from "react"
import ContentContainer from "../../../templates/ContentContainer"
import img1 from "../../../assets/OldDesign/supernodes/node1.png"
import img2 from "../../../assets/OldDesign/supernodes/node2.png"

interface NumberedSectionProps {
    order: number
    text: string
}

const NumberedSection: React.FC<NumberedSectionProps> = ({ order, text }) => {
    return (
        <div className="flex">
            <div className="border-r-2 border-old-design-green font-bold text-old-design-green font-proxima text-2xl flex items-center w-10 flex-shrink-0">
                {order}
            </div>
            <p className="ml-5 font-proxima text-white text-lg order-1 md:order-0 flex items-center">
                {text}
            </p>
        </div>
    )
}

const numberedSections: string[] = [
    `They allow tracking and payment of Secure Nodes to be moved on-chain, or in protocol, which is a major change from the current management which is done via external server clusters.`,
    `They provide the required infrastructure to implement Sidechaining which moved Horizen from a pure cryptocurrency to an application ready platform upon which an unbounded set of services can be built.`,
]

const SupernodesImprovements = () => {
    return (
        <section className="py-24 bg-old-design-dark-blue">
            <ContentContainer>
                <h2 className="font-proxima font-bold text-white text-3xl text-center">
                    Super Nodes bring two major improvements to the Horizen
                    ecosystem:
                </h2>

                <div className="mt-20 grid grid-cols-1 md:grid-cols-2 gap-12">
                    {numberedSections.map((text, index) => (
                        <NumberedSection
                            key={text}
                            order={index + 1}
                            text={text}
                        />
                    ))}
                </div>

                <hr className="border-old-design-green/50 mt-20" />

                <div className="flex gap-y-7 gap-x-14 items-center flex-col md:flex-row mt-20">
                    <img
                        src={img1}
                        alt="interconnected nodes"
                        className="h-36 w-36 mx-auto"
                    />
                    <p className="font-proxima text-white text-lg">
                        The value proposition of the system then becomes more
                        than the utility of the currency, it becomes the utility
                        of all future services that will be layered onto the
                        Horizen infrastructure.
                    </p>
                </div>

                <div className="flex gap-y-7 gap-x-14 items-center flex-col md:flex-row mt-12">
                    <p className="font-proxima text-white text-lg order-1 md:order-0">
                        By operating a Super Node, operators will receive an
                        equal share of 10% of the block rewards providing they
                        meet the acceptance criteria. Our target Super Node
                        count is between 2,000-2,500 and our target Secure node
                        count is between 20,000-25,000. Major deviations from
                        those numbers could induce future payout or staking
                        adjustments.
                    </p>
                    <img
                        src={img2}
                        alt="10% of the block reward"
                        className="h-36 w-36 mx-auto order-0 md:order-1"
                    />
                </div>
            </ContentContainer>
        </section>
    )
}

export default SupernodesImprovements
