import React from "react"
import NewsletterForm from "../components/NewsletterForm"
import MediaDiscord from "../sections/OldDesign/media/MediaDiscord"
import SupenodesRequirements from "../sections/OldDesign/supernodes/SupenodesRequirements"
import SupernodesBox from "../sections/OldDesign/supernodes/SupernodesBox"
import SupernodesButtons from "../sections/OldDesign/supernodes/SupernodesButtons"
import SupernodesCheap from "../sections/OldDesign/supernodes/SupernodesCheap"
import SupernodesHelmet from "../sections/OldDesign/supernodes/SupernodesHelmet"
import SupernodesHero from "../sections/OldDesign/supernodes/SupernodesHero"
import SupernodesImprovements from "../sections/OldDesign/supernodes/SupernodesImprovements"
import SupernodesIntro from "../sections/OldDesign/supernodes/SupernodesIntro"
import Layout from "../templates/Layout"

const supernodes = () => {
    return (
        <Layout>
            <div className="bg-horizen-dark-blue">
                <SupernodesHelmet />
                <SupernodesHero />
                <SupernodesIntro />
                <SupernodesBox />
                <SupenodesRequirements />
                <SupernodesImprovements />
                <SupernodesButtons />
                <SupernodesCheap />
                <MediaDiscord />
                <NewsletterForm />
            </div>
        </Layout>
    )
}

export default supernodes
