import React from "react"
import ContentContainer from "../../../templates/ContentContainer"
import img from "../../../assets/OldDesign/supernodes/supernodes-box.png"

const SupernodesBox = () => {
    return (
        <section className="py-12 bg-old-design-gray">
            <ContentContainer>
                <div className="flex gap-y-7 gap-x-14 items-center flex-col md:flex-row">
                    <img
                        src={img}
                        alt="super nodes box"
                        className="h-48 w-48"
                    />

                    <p className="font-proxima text-old-design-dark-blue text-lg">
                        Like Secure Nodes, the Super Node network has enhanced
                        point-to-point encryption. Super Nodes are tasked with
                        managing key network and system functions such as
                        hosting multiple services on sidechains, tracking and
                        measuring Secure Node uptime, and queuing the node
                        payment schedule for miners.
                    </p>
                </div>
            </ContentContainer>
        </section>
    )
}

export default SupernodesBox
