/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import ContentContainer from "../../../templates/ContentContainer"
import zenImg from "../../../assets/OldDesign/supernodes/sn-icon-zen.png"
import ramImg from "../../../assets/OldDesign/supernodes/sn-icon-ram.png"
import reliabilityImg from "../../../assets/OldDesign/supernodes/sn-icon-reliability.png"
import cpuImg from "../../../assets/OldDesign/supernodes/sn-icon-cpu.png"
import harddriveImg from "../../../assets/OldDesign/supernodes/sn-icon-harddrive.png"
import SectionTitle from "../../../components/OldDesign/SectionTitle"
import Link from "../../../components/OldDesign/Link"
import { URLProvider } from "../../../utils/URLProvider"
import {
    PointWithLine,
    PointWithLineProps,
    Requirement,
    RequirementT,
} from "./Requirement"

const pointWithLines: PointWithLineProps["lineDirections"][] = [
    ["top", "right"],
    ["bottom", "left", "right"],
    ["top", "left", "right"],
    ["bottom", "left", "right"],
    ["top", "left"],
]

const requirements: RequirementT[] = [
    {
        title: "500 ZEN",
        description: "In transparent address per Super Node",
        img: zenImg,
        alt: "ZEN",
    },
    {
        title: "RAM Memory",
        description: "8GB of RAM memory or more",
        img: ramImg,
        alt: "RAM",
    },
    {
        title: "96% Reliability",
        description: "Keep the node up and running at least 96% of the time",
        img: reliabilityImg,
        alt: "Reliability",
    },
    {
        title: "Mulitiple CPU Cores",
        description: "To achieve less than 100 second challenge times",
        img: cpuImg,
        alt: "CPU Cores",
    },
    {
        title: "Storage Capacity",
        description: "100 GB of storage capacity or more",
        img: harddriveImg,
        alt: "Hard drive",
    },
]

const SupenodesRequirements = () => {
    return (
        <section className="py-24 bg-horizen-dark-blue">
            <ContentContainer>
                <SectionTitle text="REQUIREMENTS" color="white" />
                <div className="grid grid-cols-1 gap-y-6 md:grid-cols-5 justify-items-center mt-20">
                    <Requirement {...requirements[0]} />
                    <div />
                    <Requirement {...requirements[1]} />
                    <div />
                    <Requirement {...requirements[2]} />
                </div>
                <div className="hidden md:grid grid-cols-5">
                    {pointWithLines.map((lineDirections, index) => (
                        <PointWithLine
                            key={`line dicrection ${index}`}
                            lineDirections={lineDirections}
                        />
                    ))}
                </div>
                <div className="grid grid-cols-1 gap-y-6 mt-6 md:mt-0 md:grid-cols-5 justify-items-center">
                    <div />
                    <Requirement {...requirements[3]} />
                    <div />
                    <Requirement {...requirements[4]} />
                    <div />
                </div>
                <p className="text-center mt-14 md:mt-20 text-white !text-xl">
                    Visit our support system for full{" "}
                    <span className="block md:inline">
                        <Link
                            href={URLProvider.URL_SUPERNODES_REQUIREMENTS}
                            newTab
                        >
                            Super Node Requirements
                        </Link>
                    </span>
                </p>
            </ContentContainer>
        </section>
    )
}

export default SupenodesRequirements
