import React from "react"
import ContentContainer from "../../../templates/ContentContainer"
import img from "../../../assets/OldDesign/supernodes/securenodes.png"
import Button from "../../../components/OldDesign/Button"

const SupernodesCheap = () => {
    return (
        <section className="py-12 bg-white">
            <ContentContainer>
                <div className="flex gap-y-7 gap-x-14 items-center flex-col md:flex-row">
                    <img src={img} alt="lock" className="h-48 w-48" />

                    <div>
                        <h3 className="font-proxima text-3xl font-semibold">
                            You don&apos;t need 500 ZEN to run a Horizen node -
                            SECURE NODES
                        </h3>
                        <p className="mt-4 mb-4 font-proxima text-old-design-dark-blue text-lg">
                            Horizen Secure Nodes enable one of the most secure,
                            distributed, and reliable network to the Horizen
                            ecosystem by providing end-to-end encryption - the
                            first to market!
                        </p>
                        <Button width="fit" href="/securenodes">
                            LEARN MORE
                        </Button>
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default SupernodesCheap
