import React from "react"
import ContentContainer from "../../../templates/ContentContainer"
import bgImg from "../../../assets/OldDesign/supernodes/supernodes.png"

const SupernodesIntro = () => {
    return (
        <section className="py-24 bg-white text-center">
            <ContentContainer>
                <img
                    src={bgImg}
                    alt="page icon"
                    className="h-36 w-36 mx-auto"
                />
                <h2 className="font-proxima font-bold text-old-design-dark-blue text-3xl mt-12">
                    The Super Node network enables sidechaining and platform
                    applications. This was a major improvement to the system
                    that moved the project well beyond a simple cryptocurrency.
                </h2>
            </ContentContainer>
        </section>
    )
}

export default SupernodesIntro
