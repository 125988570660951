import React from "react"
import { Helmet } from "react-helmet"

const SupernodesHelmet = () => {
    return (
        <Helmet>
            <title>Horizen Super Nodes</title>
            <meta property="og:title" content="Horizen Super Nodes" />
            <meta name="twitter:title" content="Horizen Super Nodes" />
            <meta
                name="description"
                content="Horizen's Super Node network supports our sidechain platform and dapps, tracking of Secure Node uptime, and queuing the node payment schedule for miners."
            />
            <meta
                property="og:description"
                content="Horizen's Super Node network supports our sidechain platform and dapps, tracking of Secure Node uptime, and queuing the node payment schedule for miners."
            />
            <meta
                name="twitter:description"
                content="Horizen's Super Node network supports our sidechain platform and dapps, tracking of Secure Node uptime, and queuing the node payment schedule for miners."
            />
            <meta
                name="keywords"
                content="horizen super node, super nodes, super node network, zencash super node, zen super node, zen cash super node"
            />
            <link rel="canonical" href="https://www.horizen.io/supernodes/" />
            <meta
                property="og:url"
                content="https://www.horizen.io/supernodes/"
            />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Horizen" />
            <meta name="twitter:site" content="@horizenglobal" />
            <meta name="twitter:creator" content="@horizenglobal" />
        </Helmet>
    )
}

export default SupernodesHelmet
