import React from "react"

interface PointWithLineProps {
    lineDirections: ("left" | "right" | "top" | "bottom")[]
    theme?: "dark" | "light"
}

const PointWithLine: React.FC<PointWithLineProps> = ({
    lineDirections,
    theme = "dark",
}) => {
    const bgColor = theme === "dark" ? "bg-white" : "bg-[#dcdcdc]"
    return (
        <div className="relative h-16 w-full my-8">
            <span
                className={`block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-full h-5 w-5 ${bgColor}`}
            />

            {lineDirections.includes("left") && (
                <span
                    className={`block absolute bg-white top-1/2 left-0 -translate-y-1/2 w-1/2 h-[2px] ${bgColor}`}
                />
            )}

            {lineDirections.includes("right") && (
                <span
                    className={`block absolute bg-white top-1/2 left-1/2 -translate-y-1/2 w-1/2 h-[2px] ${bgColor}`}
                />
            )}

            {lineDirections.includes("top") && (
                <span
                    className={`block absolute bg-white top-0 left-1/2 -translate-x-1/2 w-[2px] h-1/2 ${bgColor}`}
                />
            )}

            {lineDirections.includes("bottom") && (
                <span
                    className={`block absolute bg-white top-1/2 left-1/2 -translate-x-1/2 w-[2px] h-1/2 ${bgColor}`}
                />
            )}
        </div>
    )
}

interface RequirementT {
    title: string
    description: string
    img: string
    alt: string
    theme?: "dark" | "light"
}

const Requirement: React.FC<RequirementT> = ({
    title,
    description,
    img,
    alt,
    theme = "dark",
}) => {
    const textColor =
        theme === "dark" ? "text-white" : "text-old-design-dark-blue"

    return (
        <div
            className={`text-center font-proxima flex flex-col items-center max-w-[240px] ${textColor}`}
        >
            <img className="max-w-[109px] w-full" src={img} alt={alt} />
            <h4 className="font-bold text-2xl mt-2">{title}</h4>
            <p>{description}</p>
        </div>
    )
}

export { PointWithLine, Requirement, PointWithLineProps, RequirementT }
